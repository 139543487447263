// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--CmGpV";
export var ctBlack = "style-module--ctBlack--1A2QS";
export var ctSmall = "style-module--ctSmall--i8pNo";
export var ctMedium = "style-module--ctMedium--3Gefz";
export var ctLarge = "style-module--ctLarge--29KPZ";
export var ctXLarge = "style-module--ctXLarge--215Kf";
export var ctIntro = "style-module--ctIntro--1qXRr";
export var ctBody = "style-module--ctBody--3stfn";
export var ctBodyMedium = "style-module--ctBodyMedium--2_kvB";
export var ctBodyBold = "style-module--ctBodyBold--23nOO";
export var ctLink = "style-module--ctLink--3TxbP";
export var cta = "style-module--cta--2tiq0";
export var frutiLarge = "style-module--frutiLarge--1foN0";
export var title = "style-module--title--9bmkm";
export var ctUi = "style-module--ctUi--7WAI_";
export var ctMeta = "style-module--ctMeta--Sj4d0";
export var ctMicro = "style-module--ctMicro--1eE1V";
export var ctMediumItalic = "style-module--ctMediumItalic--1DOdG";
export var ReadMore = "style-module--ReadMore--3FK5D";
export var grid = "style-module--grid--2ALq4";