// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--sRaBc";
export var ctBlack = "style-module--ctBlack--eKuzw";
export var ctSmall = "style-module--ctSmall--1rVJd";
export var ctMedium = "style-module--ctMedium--2uY8R";
export var ctLarge = "style-module--ctLarge--1GZ9g";
export var ctXLarge = "style-module--ctXLarge--344HN";
export var ctIntro = "style-module--ctIntro--20yJG";
export var ctBody = "style-module--ctBody--261hz";
export var ctBodyMedium = "style-module--ctBodyMedium--2gA5V";
export var ctBodyBold = "style-module--ctBodyBold--3CZv7";
export var ctLink = "style-module--ctLink--1RFUI";
export var frutiLarge = "style-module--frutiLarge--3ICgQ";
export var ctUi = "style-module--ctUi--2QiRI";
export var container = "style-module--container--2y6XR";
export var ctMeta = "style-module--ctMeta--3RzuE";
export var ctMicro = "style-module--ctMicro--i8C7s";
export var ctMediumItalic = "style-module--ctMediumItalic--3Gvv0";