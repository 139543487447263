/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'
import { htmlToText } from 'html-to-text'
import Seo from '~components/globals/Seo'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'

const HadiSubscribe = () => {
  const { prismicReflections } = useStaticQuery(graphql`
    {
      prismicReflections {
        data {
          cta
          headline
          image {
            alt
            url
          }
          description {
            html
          }
          subscribe_cta {
            text
          }
          sharing_image {
            url
            alt
          }
        }
      }
    }
  `)

  const { data } = prismicReflections

  return (
    <div className={styles.HadiSubscribe}>
      <div className={styles.imageContainer}>
        <img src={data.image.url} alt={data.image.alt} />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{data.headline}</p>
        <div data-html className={styles.description}>
          {parse(data.description.html)}
        </div>
        <Link className={styles.cta} to="/subscribe">
          {/* {data.subscribe_cta.text} */}
          subscribe
        </Link>
      </div>
    </div>
  )
}

export default HadiSubscribe
