module.exports = {
  longDate: d => {
    const date = new Date(d)

    const stringDate = date.toDateString().substring(3)
    const index = stringDate.length - 5
    return `${stringDate.substring(0, index)},${stringDate.substring(index, stringDate.length)}`
  },
  getYearMonthDay: d => {
    const date = new Date(d)

    return date.toISOString().slice(0, 10)
  },
  transformToDateObj: d => {
    const date = new Date(d)

    return date
  },
}
