/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { htmlToText } from 'html-to-text'
import uniqid from 'uniqid'
import parse from 'html-react-parser'
import linkedinIcon from '../../images/linkdn.svg'
import twitterIcon from '../../images/twitter.svg'
import mailIcon from '../../images/mail.svg'
import Container from '~components/globals/Container'
import Seo from '~components/globals/Seo'
// import SubscriptionBanner from '~components/SubscriptionBanner'
import ReadMore from '~components/ReadMore'
import HadiSubscribe from '~components/HadiSubscribe'
import * as styles from './style.module.scss'
import { longDate } from '../../helpers/date'
import Tag from '~components/Tag'

export const Article = ({
  location,
  pageContext: {
    page: { data },
    allPrismicTopicPage,
    ceoSharing,
  },
}) => {
  const {
    content,
    date,
    headline,
    highlight,
    image,
    image_caption,
    intro_text,
    source,
    topics,
    type,
  } = data
  const [shareUrl, setShareUrl] = useState(null)

  useEffect(() => {
    const url = window.location.pathname
    // const replacedUrl = url.replace('/', '%2F').replace('/', '%2F')
    const replacedUrl = url.replace('/', '%2F').replace('/', '%2F')

    // Todo
    if (window.location.host === 'www.medtechpulse.com') {
      setShareUrl(`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fmedtechpulse.com${replacedUrl}`)
    } else {
      setShareUrl(`https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fmedtech-pulse-v2.netlify.app${replacedUrl}`)
    }
  }, [])

  return (
    <div className={styles[type]}>
      <Container extraClassName={styles.container}>
        {type !== 'ceo' && (
          <Seo
            title={headline.text}
            description={htmlToText(intro_text.html, {
              wordwrap: false,
            })}
            image={image.url}
            url={location.href}
          />
        )}
        {type === 'ceo' && (
          <Seo
            title={headline.text}
            description={htmlToText(ceoSharing?.description?.html, {
              wordwrap: false,
            })}
            image={ceoSharing?.sharing_image?.url}
            url={location.href}
          />
        )}
        <article>
          <div className={styles.mediumContainer}>
            {topics && topics[0]?.topic.slug && (
              <div className={styles.themes}>
                {topics.map(t => (
                  <a href={`/topic/${t.topic.slug}`}>
                    <Tag
                      text={allPrismicTopicPage.nodes?.filter(item => item.data.topic.slug === t.topic.slug)[0].data.navigation_name.text}
                      key={uniqid()}
                    />
                  </a>
                ))}
              </div>
            )}
            <h1 className={styles.headline}>{headline.text}</h1>
            <time className={styles.date}>{longDate(date)}</time>
          </div>
          <div className={styles.mediumContainer}>
            {intro_text?.html && (
              <div data-html className={styles.introText}>
                {parse(intro_text.html)}
              </div>
            )}
          </div>
          {image.url && (
            <div className={styles.imageContainer}>
              <img src={image.url} alt={image.alt} className={styles.image} role="presentation" />
              <div className={styles.mediumContainer}>
                <figcaption className={styles.caption}>{image_caption.text}</figcaption>
              </div>
            </div>
          )}
          <div className={styles.mediumContainer}>
            {content?.html && (
              <div data-html className={styles.content}>
                {parse(content.html)}
              </div>
            )}
            {/* {highlight.html && (
              <div data-html className={styles.highlightedContent}>
                {parse(highlight.html)}
              </div>
            )} */}
            {source && (
              <div className={styles.source}>
                {parse(source.html)}
              </div>
            )}
            {shareUrl && (
              <div className={styles.shareWrapper}>
                <a
                  className={styles.share}
                  target="_blank"
                  href={shareUrl}
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} alt="Linkedin" />
                </a>
                <a
                  className={styles.share}
                  target="_blank"
                  href={`https://twitter.com/intent/tweet?url=${window.location.href}&text=`}
                  rel="noreferrer"
                >
                  <img src={twitterIcon} alt="Twitter" />
                </a>
                <a
                  className={styles.share}
                  target="_blank"
                  href={`mailto:?subject=${encodeURIComponent(headline.text)}&body=${window.location.href}`}
                  rel="noreferrer"
                >
                  <img src={mailIcon} alt="Mail" />
                </a>
              </div>
            )}
          </div>
        </article>
        {type === 'ceo' && (
          <HadiSubscribe />
        )}
      </Container>
      <ReadMore type={type} topics={topics[0]} />
    </div>
  )
}
export default Article
