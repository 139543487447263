/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'
import { Link } from 'gatsby'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'
import SingleArticleTeaser from '~components/globals/SingleArticleTeaser'

const ReadMore = ({ type, topics }) => {
  const { allPrismicArticle, prismicNextRead } = useStaticQuery(graphql`
    {
      allPrismicArticle(sort: {fields: data___date, order: DESC}, filter: {data: {type: {ne: "ceo"}}}) {
        nodes {
          data {
            image {
              alt
              url
            }
            image_preview {
              alt
              url
            }
            headline {
              text
            }
            topics {
              topic {
                slug
              }
            }
            type
          }
          uid
        }
      }
      prismicNextRead {
        data {
          cta {
            text
          }
          headline {
            text
          }
        }
      }
    }
  `)

  const { cta, headline } = prismicNextRead.data

  const [filteredArticles, setFilteredArticles] = useState([])

  useEffect(() => {
    const array = []
    allPrismicArticle.nodes.forEach(el => {
      el.data.topics.forEach(elTopic => {
        // topics.forEach(topic => {
        //   const isIncluded = array.some(e => e.uid === el.uid)
        //   if (!isIncluded && elTopic?.topic?.slug?.includes(topic.topic.slug)) {
        //     array.push(el)
        //   }
        // })
        if (elTopic?.topic?.slug?.includes(topics?.topic.slug)) {
          array.push(el)
        }
      })
    })
    setFilteredArticles(array.length ? array : allPrismicArticle.nodes)
  }, [])

  return (
    <div className={styles.ReadMore}>
      <Container>
        {topics?.topic?.slug ? (
          <Link to={`/topic/${topics.topic.slug}`} className={styles.title}>
            {headline.text}&nbsp;
            <span>
              {topics.topic.slug}
            </span>
          </Link>
        ) : (
          <p className={styles.title}>
            Read more
          </p>
        )}
        <div className={styles.grid}>
          {filteredArticles.slice(0, 3).map(item => (
            <SingleArticleTeaser
              data={item.data}
              key={uniqid()}
              uid={item.uid}
            />
          ))}
        </div>
        {topics?.topic?.slug && (
          <Link className={styles.cta} to={`/topic/${topics.topic.slug}`}>
            {cta.text}
          </Link>
        )}
        {!topics?.topic?.slug && type !== 'ceo' && (
          <Link className={styles.cta} to={`/${type}s`}>
            {cta.text}
          </Link>
        )}
      </Container>
    </div>
  )
}

export default ReadMore
