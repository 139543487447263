import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.scss'

const Tag = ({ bgColor, text, textColor }) => (
  <div className={styles.container} style={{ color: textColor, backgroundColor: bgColor }}>
    {text}
  </div>
)

export default Tag

Tag.propTypes = {
  bgColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
}
